import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
    
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';  // Import Bootstrap JS
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { GoogleOAuthProvider } from '@react-oauth/google';

const GOOGLE_CLIENT_ID = '916977580664-tkfl69eridg63v6l0lq46fflkkarsflh.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>

  {/* <I18nextProvider i18n={i18n}>
    <App />
    </I18nextProvider> */}
    <React.StrictMode>
<App />
</React.StrictMode>
    </GoogleOAuthProvider>,



);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
