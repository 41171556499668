import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import range from 'lodash/range';
import { useTranslation, } from "react-i18next";
import active from '../assets/images/product-details/step-active.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import left from '../assets/images/product-details/left.png';
import right from '../assets/images/product-details/right.png';
import ApiService from '../service/ApiService';
import AppEndPoints from '../config/AppEndPoints';
import StaticMethod from '../service/staticmethod';
import AuthService from '../service/authService';
import './book.css';
// import '../assets/css/english.css';
// import '../assets/css/arabic.css';

const BookSection = ({ carId, brandName, carName, addOns, carImg, close }) => {
    const [selectedType, setSelectedType] = useState("2");
    const [currentDate, setCurrentDate] = useState(moment());
    const [namesOfDays] = useState(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
    const [weeks, setWeeks] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEndWeek, setSelectedEndWeek] = useState(null);
    const [show, setShow] = useState(false);
    const [selectedStartWeek, setSelectedStartWeek] = useState(null);
    const [blnProfileModal, setBlnProfileModal] = useState(false);
    const [blnUploadModal, setBlnUploadModal] = useState(false);
    const [blnConfirmationModal, setBlnConfirmationModal] = useState(false);
    const [blnCalendarModal, setBlnCalendarModal] = useState(true);
    const [blnSuccessModal, setBlnSuccessModal] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [blnStartDateSelected, setBlnStartDateSelected] = useState(false);
    const [shortLink, setShortLink] = useState("");
    const [moveNext, setNextSetp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [fileLicenceFront, setFileLicenceFront] = useState(null);
    const [fileLicenceFrontName, setFileLicenceFrontName] = useState(null);
    const [fileLicenceBack, setFileLicenceBack] = useState(null);
    const [fileLicenceBackName, setFileLicenceBackName] = useState(null);
    const [fileEmiratesFront, setFileEmiratesFront] = useState(null);
    const [fileEmiratesFrontName, setFileEmiratesFrontName] = useState(null);
    const [fileEmiratesBack, setFileEmiratesBack] = useState(null);
    const [fileEmiratesBackName, setFileEmiratesBackName] = useState(null);
    const [filePassport, setFilePassport] = useState(null);
    const [filePassportName, setFilePassportName] = useState(null);
    const [fileVisa, setFileVisa] = useState(null);
    const [fileVisaName, setFileVisaName] = useState(null);
    const [lstFiles, setLstFiles] = useState(null);
    const [isDrivingLicenceFrontUploaded, setIsDrivingLicenceFrontUploaded] = useState(false);
    const [isDrivingLicenceBackUploaded, setIsDrivingLicenceBackUploaded] = useState(false);
    const [isEmiratesFrontUploaded, setIsEmiratesFrontUploaded] = useState(false);
    const [isEmiratesBackUploaded, setIsEmiratesBackUploaded] = useState(false);
    const [isPassportUploaded, setIsPassportUploaded] = useState(false);
    const [isVisaUploaded, setIsVisaUploaded] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState(1);
    const [bookingId, setBookingId] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [lstDocuments, setLstDocuments] = useState([]);
    const [blnCalSubmitted, setBlnCalSubmitted] = useState(false);
    const [showEmiratesUpload, setShowEmiratesUpload] = useState(true);
    const [lstYear, setLstYear] = useState([]);
    const [currentYear, setCurrentYear] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);
    const [wrongDate, setWrongDate] = useState(false);
    const [dateMessage, setDateMessage] = useState("");
    const [fileName, setFileName] = useState(null);
    const [frmData, setFrmData] = useState(new FormData());
    const calendarRef = useRef(null);
    const [hoverDate, setHoverDate] = useState(null);
    const [startMoment, setStartMoment] = useState(null);
    const [endMoment, setEndMoment] = useState(null);
    const [blnUploadStart, setBlnUploadStart] = useState(false);
    const [blnConfirm, setBlnConfirm] = useState(false);
    const [countries, setCountries] = useState([]);
    const [touristOrResident, setTouristOrResident] = useState("RESIDENT");
    const [status, setStatus] = useState({ isPhone: null, isVerified: null });
    const [profileData, setProfileData] = useState({
        name: '',
        contactNumber: '',
        email: '',
        address: ''
    });

    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;
    const lstMonth = [
        { name: 'January', id: 0 },
        { name: 'February', id: 1 },
        { name: 'March', id: 2 },
        { name: 'April', id: 3 },
        { name: 'May', id: 4 },
        { name: 'June', id: 5 },
        { name: 'July', id: 6 },
        { name: 'August', id: 7 },
        { name: 'September', id: 8 },
        { name: 'October', id: 9 },
        { name: 'November', id: 10 },
        { name: 'December', id: 11 }
    ];

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value
        });
    };
    const handleProfileSubmit = (e) => {
        e.preventDefault();

        // Example: Basic validation (You can add more)
        if (!profileData.name || !profileData.contactNumber || !profileData.email) {
            toast.error('Please fill in all required fields');
            return;
        }

        // If you need to submit the data to an API, you can do it here
        // Example: ApiService.post('/profile', profileData);

        // Move to the next step after successful submission
        handleProfileNext();
    };

    const handleProfileNext = () => {
        setBlnProfileModal(false); // Close the profile modal
        setBlnUploadModal(true);   // Open the upload document modal (Step 3)
    };
    const handleProfileCancel = () => {
        // Example: Reset the profile data (optional)
        setProfileData({
            name: '',
            contactNumber: '',
            email: '',
            address: ''
        });

        // Close the profile modal and possibly go back to the previous step or home
        setBlnProfileModal(false);
        setBlnCalendarModal(true);

        // Optionally, you might want to navigate back to the previous step or a specific screen
        // Example: setBlnCalendarModal(true); // If you want to go back to the calendar modal
    };


    useEffect(() => {
        fetchCode();
        generateCalendar();
        populateYears();
    }, [currentDate]);

    useEffect(() => {

        getDocuments(selectedPosition);
        setCurrentYear(parseInt(moment(currentDate).format('YYYY')));
        setCurrentMonth(parseInt(moment(currentDate).format('MM')));
        let year = currentYear;
        while (year < currentYear + 20) {
            setLstYear(prevLstYear => [...prevLstYear, year]);
            year = year + 1;
        }
        setSelectedDate(moment(currentDate).format('DD/MM/YYYY'));
        console.log(moment(currentDate).format('DD/MM/YYYY'));
        console.log(parseInt(moment(currentDate).format('MM')));
        console.log(parseInt(moment(currentDate).format('YYYY')));
        generateCalendar();
        sessionStorage.setItem('bookNowOpened', '0');
    }, []);

    useEffect(() => {
        reset();
        setCurrentDate(moment());
        setSelectedDate(moment(currentDate).format('DD/MM/YYYY'));

        generateCalendar();
    }, [selectedPosition]);

    const generateCalendar = () => {
        const dates = fillDates(currentDate);
        const weeks = [];
        while (dates.length > 0) {
            weeks.push(...dates.splice(0, 7));
        }

        setWeeks(weeks);
    };


    const nextStep = () => {
        setBlnCalendarModal(false);
        setNextSetp(true);

    }
    // const fillDates = (currentMoment) => {
    //     const firstOfMonth = moment(currentMoment).startOf('month').day();
    //     const lastOfMonth = moment(currentMoment).endOf('month').day();
    //     let firstDayOfGrid;
    //     let lastDayOfGrid;
    //     if (firstOfMonth === 0) {
    //         firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth + 6, 'days');
    //         lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth + 6, 'days').add(7, 'days');
    //     } else {
    //         firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth - 1, 'days');
    //         lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth - 1, 'days').add(7, 'days');
    //     }
    //     const startCalendar = firstDayOfGrid.date();
    //     return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'days')).map((date) => {
    //         const newDate = moment(firstDayOfGrid).date(date);
    //         return {
    //             today: isToday(newDate),
    //             selected: isSelected(newDate),
    //             sameMonth: isMonth(newDate),
    //             disabled: moment(newDate).isBefore(moment()) || !isMonth(newDate),
    //             mDate: newDate,
    //             mouseOver: false
    //         };
    //     });
    // };
    const fillDates = (currentMoment) => {
        const startOfMonth = moment(currentMoment).startOf('month');
        const startDay = startOfMonth.day();
        const daysInMonth = moment(currentMoment).daysInMonth();
        const calendar = [];

        for (let i = 0; i < startDay; i++) {
            calendar.push({ mDate: null, disabled: true });
        }

        for (let date = 1; date <= daysInMonth; date++) {
            const day = moment(startOfMonth).date(date);
            calendar.push({ mDate: day, today: isToday(day), sameMonth: true });
        }

        while (calendar.length % 7 !== 0) {
            calendar.push({ mDate: null, disabled: true });
        }

        return calendar;
    };


    const isToday = (date) => moment().isSame(moment(date), 'day');

    const isMonth = (date) => moment(date).isSame(moment(currentDate), 'month');

    const isSelected = (date) => moment(date).isBefore(selectedEndWeek) && moment(date).isAfter(selectedStartWeek) || moment(date?.format('YYYY-MM-DD')).isSame(selectedStartWeek?.format('YYYY-MM-DD')) || moment(date?.format('YYYY-MM-DD')).isSame(selectedEndWeek?.format('YYYY-MM-DD'));

    // const prevMonth = () => {
    //     setCurrentDate(moment(currentDate).subtract(1, 'months'));
    //     setCurrentYear(parseInt(moment(currentDate).format('YYYY')));
    //     setCurrentMonth(parseInt(moment(currentDate).format('MM')));
    //     generateCalendar();
    // };

    // const nextMonth = () => {
    //     setCurrentDate(moment(currentDate).add(1, 'months'));
    //     setCurrentYear(parseInt(moment(currentDate).format('YYYY')));
    //     setCurrentMonth(parseInt(moment(currentDate).format('MM')));
    //     generateCalendar();
    // };

    const prevMonth = () => {
        setCurrentDate(moment(currentDate).subtract(1, 'month'));
        generateCalendar();
    };

    const nextMonth = () => {
        setCurrentDate(moment(currentDate).add(1, 'month'));
        generateCalendar();
    };
    const isDisabledMonth = (currentDate) => moment(currentDate).isBefore(moment(), 'months');

    const prevYear = () => {
        setCurrentDate(moment(currentDate).subtract(1, 'year'));
        setCurrentYear(parseInt(moment(currentDate).format('YYYY')));
        setCurrentMonth(parseInt(moment(currentDate).format('MM')));
        generateCalendar();
    };

    const nextYear = () => {
        setCurrentDate(moment(currentDate).add(1, 'year'));
        setCurrentYear(parseInt(moment(currentDate).format('YYYY')));
        setCurrentMonth(parseInt(moment(currentDate).format('MM')));
        generateCalendar();
    };

    const dateClicked = (week) => {
        // Add your date click logic here
    };

    const dateHover = (week) => {
        // Add your date hover logic here
    };

    const monthChanged = (e) => {
        const newMonth = parseInt(e.target.value);
        const updatedDate = moment(currentDate).month(newMonth);
        setCurrentDate(updatedDate);
        generateCalendar();
    };

    const yearChanged = (e) => {
        const newYear = parseInt(e.target.value);
        const updatedDate = moment(currentDate).year(newYear);
        setCurrentDate(updatedDate);
        generateCalendar();
    };

    const populateYears = () => {
        const years = [];
        let year = moment().year();
        while (year < moment().year() + 20) {
            years.push(year);
            year += 1;
        }
        setLstYear(years);
    };

    // const fetcDocType = async (e, type) => {
    //     const token = localStorage.getItem('token'); // Ensure you have the token stored in localStorage
    //     // console.log(token);

    //     try{
    //         const headers = {
    //             'X-Localization': currentLanguage, // Example header to specify language
    //             Authorization: `Bearer ${token}`, // Example header to specify language
    //             'Access-Control-Max-Age': '3600',
    //             // Add other headers as needed
    //           };
    //           ApiService.getData(`${AppEndPoints.get_doc}/${type}`,headers )
    //           .then((res) => {
    //             console.log(res.data.data);
    //             setLstDocuments(res.data.data.documents);
    //           })
    //         // const respoonse = await ApiService.getData(`${AppEndPoints.get_doc}/${type}`);
    //         // console.log(respoonse.data.data);

    //     }catch(error){

    //     }
    // }


    const reset = () => {
        setWeeks([]);
        setSelectedDate(null);
        setSelectedEndWeek(null);
        setShow(false);
        setSelectedStartWeek(null);
        setBlnUploadModal(false);
        setBlnConfirmationModal(false);
        setBlnCalendarModal(true);
        setBlnSuccessModal(false);
        setStartDate(null);
        setEndDate(null);
        setBlnStartDateSelected(false);
        setShortLink("");
        setLoading(false);
        setFile(null);
        setFileLicenceFront(null);
        setFileLicenceFrontName(null);
        setFileLicenceBack(null);
        setFileLicenceBackName(null);
        setFileEmiratesFront(null);
        setFileEmiratesFrontName(null);
        setFileEmiratesBack(null);
        setFileEmiratesBackName(null);
        setFilePassport(null);
        setFilePassportName(null);
        setFileVisa(null);
        setFileVisaName(null);
        setLstFiles(null);
        setIsDrivingLicenceFrontUploaded(false);
        setIsDrivingLicenceBackUploaded(false);
        setIsEmiratesFrontUploaded(false);
        setIsEmiratesBackUploaded(false);
        setIsPassportUploaded(false);
        setIsVisaUploaded(false);
        setSelectedPosition(1);
        setBookingId(null);
        setFromDate(null);
        setToDate(null);
        setLstDocuments([]);
        setBlnCalSubmitted(false);
        setShowEmiratesUpload(true);
        setLstYear([]);
        setCurrentYear(null);
        setCurrentMonth(null);
        setWrongDate(false);
        setDateMessage("");
        setFileName(null);
        setFrmData(new FormData());
        setHoverDate(null);
        setStartMoment(null);
        setEndMoment(null);
        setBlnUploadStart(false);
        setBlnConfirm(false);
        setTouristOrResident("RESIDENT");
    };

    const confirm = () => {
        fetchStatus();
        setBlnCalSubmitted(true);
        setBlnCalendarModal(false);
        setBlnProfileModal(true);
        getDocuments('1');
        // setBlnUploadModal(true);

        setSelectedStartWeek(moment(selectedStartWeek).format('DD/MM/YYYY'));
        setSelectedEndWeek(moment(selectedEndWeek).format('DD/MM/YYYY'));
        setBlnConfirm(false);
        setStartDate(moment(selectedStartWeek).format('YYYY-MM-DD'));
        setEndDate(moment(selectedEndWeek).format('YYYY-MM-DD'));
    };


    const fetchCode =  async () => {
        try{
            const countryResponse = await AuthService.countryCode();
            if (countryResponse.data.errorCode === 0) {
                setCountries(countryResponse.data.data);
            }
        } catch (error){

        }
    }




    const fetchStatus = async () => {
        const result = await StaticMethod.checkBookStatus();
        setStatus(result);
        // if(result.isVerified === false){
        console.log(result);
        // }
    };

    const getDocuments = (documentType) => {
        const token = localStorage.getItem('token'); // Ensure you have the token stored in localStorage
        // console.log(token);

        try {
            const headers = {
                'X-Localization': currentLanguage, // Example header to specify language
                Authorization: `Bearer ${token}`, // Example header to specify language
                'Access-Control-Max-Age': '3600',
                // Add other headers as needed
            };
            ApiService.getData(`${AppEndPoints.get_doc}/${documentType}`, headers)
                .then((res) => {
                    console.log(res.data.data);
                    setLstDocuments(res.data.data.documents);
                })
            // const respoonse = await ApiService.getData(`${AppEndPoints.get_doc}/${type}`);
            // console.log(respoonse.data.data);

        } catch (error) {

        }
    };

    const handleFileChange = (e, setter, nameSetter) => {
        const file = e.target.files[0];
        setter(file);
        nameSetter(file.name);
    };

    const handleSubmit = () => {
        if (blnUploadStart) {
            toast.error('Please wait until the upload is complete');
            return;
        }
        setBlnUploadStart(true);
        let formData = new FormData();
        formData.append('carId', carId);
        formData.append('brandName', brandName);
        formData.append('carName', carName);
        formData.append('carImg', carImg);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        formData.append('touristOrResident', touristOrResident);
        formData.append('selectedPosition', selectedPosition);
        if (fileLicenceFront) formData.append('fileLicenceFront', fileLicenceFront, fileLicenceFrontName);
        if (fileLicenceBack) formData.append('fileLicenceBack', fileLicenceBack, fileLicenceBackName);
        if (fileEmiratesFront) formData.append('fileEmiratesFront', fileEmiratesFront, fileEmiratesFrontName);
        if (fileEmiratesBack) formData.append('fileEmiratesBack', fileEmiratesBack, fileEmiratesBackName);
        if (filePassport) formData.append('filePassport', filePassport, filePassportName);
        if (fileVisa) formData.append('fileVisa', fileVisa, fileVisaName);

        // axios.post(`http://74.208.124.215:2000/api/booking`, formData)
        //   .then(response => {
        //     setBookingId(response.data.bookingId);
        //     setBlnUploadModal(false);
        //     setBlnSuccessModal(true);
        //     setShortLink(response.data.shortLink);
        //     toast.success('Booking successful!');
        //   })
        //   .catch(error => {
        //     console.error("There was an error with the booking!", error);
        //     toast.error('Booking failed!');
        //   })
        //   .finally(() => setBlnUploadStart(false));
    };
    return (
        <>
            <div className={`language-${currentLanguage}`}>
                <div class="booking-sec">

                    {/*    <!-------Progress Steps---------->*/}
                    {/* <div className="container">
                        {!blnSuccessModal && (
                            <div className="progress-bar">

                                
                                <div className="step">
                                    <div
                                        className={`bullet ${blnCalendarModal ? 'active' : ''
                                            } ${blnUploadModal || blnConfirmationModal || blnSuccessModal ? 'activated' : ''
                                            }`}
                                    >
                                        <span>01</span>
                                    </div>
                                    <div
                                        className={`check ${blnCalendarModal ||
                                            blnUploadModal ||
                                            blnConfirmationModal ||
                                            blnSuccessModal ? 'active' : ''
                                            }`}
                                    >
                                        <img src={active} alt="" />
                                    </div>
                                </div>

                                
                                <div className="step">
                                    <div
                                        className={`bullet ${blnProfileModal ? 'active' : ''
                                            } ${blnUploadModal || blnConfirmationModal || blnSuccessModal ? 'activated' : ''
                                            }`}
                                    >
                                        <span>02</span>
                                    </div>
                                    <div
                                        className={`check ${blnProfileModal 
                                            ||
                                            blnUploadModal ||
                                            blnConfirmationModal ||
                                            blnSuccessModal 
                                            ? 'active' : ''
                                            }`}
                                    >
                                        <img src={active} alt="" />
                                    </div>
                                </div>

                                
                                <div className="step">
                                    <div
                                        className={`bullet ${blnUploadModal ? 'active' : ''
                                            } ${blnConfirmationModal ? 'activated' : ''
                                            }`}
                                    >
                                        <span>03</span>
                                    </div>
                                    <div
                                        className={`check ${blnUploadModal || blnConfirmationModal || blnSuccessModal ? 'active' : ''
                                            }`}
                                    >
                                        <img src={active} alt="" />
                                    </div>
                                </div>

                                
                                <div className="step">
                                    <div
                                        className={`bullet ${blnConfirmationModal ? 'active' : ''
                                            }`}
                                    >
                                        <span>04</span>
                                    </div>
                                    <div
                                        className={`check ${blnConfirmationModal || blnSuccessModal ? 'active' : ''
                                            }`}
                                    >
                                        <img src={active} alt="" />
                                    </div>
                                </div>

                            </div>
                        )}
                    </div> */}


                    {/* Progress Steps */}
                    <div className="container">
                        <div className="progress-bar">
                            {/* Step 1 */}
                            <div className="step">
                                <div
                                    className={`bullet ${blnCalendarModal ? 'active' : ''} 
                ${blnProfileModal || blnUploadModal || blnConfirmationModal || blnSuccessModal ? 'activated' : ''}`}
                                >
                                    <span>01</span>
                                </div>
                                <div
                                    className={`check ${blnCalendarModal || blnProfileModal || blnUploadModal || blnConfirmationModal || blnSuccessModal ? 'active' : ''}`}
                                >
                                    <img src={active} alt="" />
                                </div>
                            </div>

                            {/* Step 2 */}
                            <div className="step">
                                <div
                                    className={`bullet ${blnProfileModal ? 'active' : ''} 
                ${blnUploadModal || blnConfirmationModal || blnSuccessModal ? 'activated' : ''}`}
                                >
                                    <span>02</span>
                                </div>
                                <div
                                    className={`check ${blnProfileModal || blnUploadModal || blnConfirmationModal || blnSuccessModal ? 'active' : ''}`}
                                >
                                    <img src={active} alt="" />
                                </div>
                            </div>

                            {/* Step 3 */}
                            <div className="step">
                                <div
                                    className={`bullet ${blnUploadModal ? 'active' : ''} 
                ${blnConfirmationModal || blnSuccessModal ? 'activated' : ''}`}
                                >
                                    <span>03</span>
                                </div>
                                <div
                                    className={`check ${blnUploadModal || blnConfirmationModal || blnSuccessModal ? 'active' : ''}`}
                                >
                                    <img src={active} alt="" />
                                </div>
                            </div>

                            {/* Step 4 */}
                            <div className="step">
                                <div
                                    className={`bullet ${blnConfirmationModal ? 'active' : ''} 
                ${blnSuccessModal ? 'activated' : ''}`}
                                >
                                    <span>04</span>
                                </div>
                                <div
                                    className={`check ${blnConfirmationModal || blnSuccessModal ? 'active' : ''}`}
                                >
                                    <img src={active} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*    <!-------End Progress Steps---------->*/}

                    {/*    <!-------Choose Date section---------->*/}
                    {
                        blnCalendarModal && (
                            <div className='details'>
                                <div class="container">
                                    <h4 style={{ textAlign: 'start' }} class="choose-h4">{t('BOOKNOW.CHOOSEDATES')}</h4>
                                </div>

                                <div className="leftSec">
                                    {/* Calendar */}
                                    <div className="calendar">
                                        <div className="month">
                                            <a onClick={prevMonth} className="nav">
                                                <img src={left} alt="" />
                                            </a>

                                            <div className="selectDate">
                                                <div className="selectMonth">
                                                    <select className="form-select"
                                                        aria-label="Default select example"
                                                        //onChange={monthChanged}
                                                        onChange={monthChanged}
                                                    >
                                                        {/* {lstMonth.map((item, index) => (
                                                            <option key={index} value={item.id} selected={item.id === currentMonth - 1}>
                                                                {item.name}
                                                            </option>
                                                        ))} */}
                                                        {lstMonth.map((month, index) => (
                                                            <option key={index} value={month.id}>
                                                                {month.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="selectYear">
                                                    <select
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                        // onChange={yearChanged}
                                                        onChange={yearChanged}
                                                    >
                                                        {lstYear.map((year) => (
                                                            <option key={year} value={year}>{year}</option>
                                                        ))}
                                                        {/* {[...Array(100)].map((_, i) => (
                                                            <option key={i} value={moment().year() - 50 + i}>
                                                                {moment().year() - 50 + i}
                                                            </option>
                                                        ))} */}
                                                    </select>
                                                </div>
                                            </div>

                                            <a onClick={nextMonth} className="nav">
                                                <img src={right} alt="" />
                                            </a>
                                        </div>
                                        <div className="days">
                                            <span>{t('BOOKNOW.MON')}</span>
                                            <span>{t('BOOKNOW.TUE')}</span>
                                            <span>{t('BOOKNOW.WED')}</span>
                                            <span>{t('BOOKNOW.THU')}</span>
                                            <span>{t('BOOKNOW.FRI')}</span>
                                            <span>{t('BOOKNOW.SAT')}</span>
                                            <span>{t('BOOKNOW.SUN')}</span>
                                            {/* {weeks.map((week, index) => (
                                                <button key={index} className={week.today ? 'today' : ''}>
                                                    {week.mDate ? week.mDate.date() : ''}
                                                </button>
                                            ))} */}
                                        </div>
                                        <div className="dates">
                                            {/* {weeks.map((week, index) => (
                                                <button
                                                    key={index}
                                                    disabled={week.disabled && !week.today}
                                                    onClick={() => dateClicked(week)}
                                                    onMouseOver={() => dateHover(week)}
                                                    className={`${week.today && week.mDate.month() === currentDate.month() ? 'today' : ''} 
                          ${(blnStartDateSelected && startMoment < week.mDate && hoverDate > week.mDate && week.sameMonth) ||
                                                            (startMoment < week.mDate && endMoment > week.mDate && week.sameMonth) ? 'dateHover' : ''} 
                          ${startDate === week.mDate.format('DD-MM-YYYY') || endDate === week.mDate.format('DD-MM-YYYY') ? 'startAndEndDate' : ''} 
                          ${week.disabled && !week.today ? 'disabled' : ''}`}
                                                >
                                                    <time style={{ display: week.sameMonth ? 'block' : 'none' }}>{week.mDate.date()}</time>
                                                </button> 
                                            ))} */}
                                            {weeks.map((week, index) => (
                                                <button
                                                    key={index}
                                                    disabled={week.disabled && !week.today}
                                                    onClick={() => dateClicked(week)}
                                                    onMouseOver={() => dateHover(week)}
                                                    className={`
                ${week.today && week.mDate && week.mDate.month() === currentDate.month() ? 'today' : ''} 
                ${week.mDate && week.mDate.month() === currentDate.month() ? '' : 'disabled'}
            `}
                                                >
                                                    <time style={{ display: week.sameMonth ? 'block' : 'none' }}>
                                                        {week.mDate ? week.mDate.date() : ''}
                                                    </time>
                                                </button>
                                            ))}

                                        </div>
                                    </div>
                                    {/* End Calendar */}
                                </div>


                                <div className="rightSec" style={{ textAlign: 'start' }}>
                                    <h4>{/* Replace with your translation method */}{t('BOOKNOW.SUMMARY')}</h4>

                                    <div className="box-combonents">
                                        <div className="car-image">
                                            <img
                                                style={{

                                                    objectFit: 'contain' /* Change to 'contain' if you want to fit the image inside the container */
                                                }}
                                                src={carImg} alt="" className="img-fluid" />
                                        </div>

                                        <div className="car-name">
                                            <h5>{brandName}</h5>
                                            <h3>{carName}</h3>
                                        </div>
                                    </div>

                                    {/* Start and End date */}

                                    <div className="start-end-dates" style={{ position: 'relative' }}>
                                        <div className="start-date">
                                            <p>{/* Replace with your translation method */}{t('BOOKING.STARTDATE')}</p>
                                            <input
                                                type="tel"
                                                id="startdate"
                                                value={startDate}
                                                name="startdate"
                                                readOnly
                                                placeholder={t('PLACEHOLDER.ADDDATE')}
                                                required
                                            />
                                        </div>
                                        {!startDate && blnCalSubmitted && (
                                            <span style={{ position: 'absolute', bottom: '-20px', color: 'red', fontSize: '10px' }}>
                                                {/* Replace with your translation method */}{t('VALIDATION.STARTDATEREQUIRED')}
                                            </span>
                                        )}
                                        <div className="end-date">
                                            <p>{/* Replace with your translation method */}{t('BOOKING.ENDDATE')}</p>
                                            <input
                                                type="tel"
                                                id="enddate"
                                                value={endDate}
                                                name="enddate"
                                                readOnly
                                                placeholder={t('PLACEHOLDER.ADDDATE')}
                                                required
                                            />
                                        </div>
                                        {!endDate && blnCalSubmitted && (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '-20px',
                                                    right: '12px',
                                                    color: 'red',
                                                    fontSize: '10px',
                                                }}
                                            >
                                                {/* Replace with your translation method */}{t('VALIDATION.ENDATEISREDUIRED')}
                                            </span>
                                        )}
                                        {wrongDate && (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '-20px',
                                                    right: '12px',
                                                    color: 'red',
                                                    fontSize: '10px',
                                                }}
                                            >
                                                {dateMessage}
                                            </span>
                                        )}
                                    </div>

                                    {/* End Start and End date Section */}

                                    {/* Cancel - Next Button */}

                                    <div className="nex-prev">
                                        <a
                                            // onClick={closeModal}
                                            className="cancel">
                                            {/* Replace with your translation method */}{t('BOOKNOW.CANCEL')}
                                        </a>

                                        <a className="next"
                                            onClick={confirm}
                                        >
                                            {/* Replace with your translation method */}{t('BOOKNOW.NEXT')}
                                        </a>
                                    </div>

                                    {/* End Cancel - Next Button */}
                                </div>


                            </div>
                        )

                    }
                    {/*    <!-------End Choose Date section---------->*/}

                    {/*    <!-------Profile section---------->*/}
                    {blnProfileModal && (
                        <div className="profile-section">
                            <div className="container">
                                <h4>{t('BOOKNOW.CHOOSEDATES')}</h4>
                                <form onSubmit={handleProfileSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="name">{t('PROFILE.FULLNAME')}</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={profileData.name}
                                            onChange={handleProfileChange}
                                            placeholder={t('PROFILE.ENTERYOURNAME')}
                                            required
                                        />
                                    </div>
                                    {/* <div className="form-group">
                    <label htmlFor="contactNumber">{t('PROFILE.CONTACTNUMBER')}</label>
                    <input
                        type="tel"
                        id="contactNumber"
                        name="contactNumber"
                        value={profileData.contactNumber}
                        onChange={handleProfileChange}
                        placeholder={t('PROFILE.ENTERCONTACTNUMBER')}
                        required

                    />
                </div> */}
                                    <div className="form-group posRelative" style={{ textAlign: 'start' }}>
                                        <label htmlFor="InputContact1">{t('PROFILE.CONTACTNUMBER')}</label>
                                        <div className="d-flex">
                                            <select
                                                name="country_id"
                                                id="country-code"
                                                className="country-code-select"
                                                value={profileData.country_id}
                                                onChange={handleProfileChange}
                                            >
                                                {/* Example options, replace with dynamic data */}
                                                {countries.map((country) => (
                                                                <option key={country.id} value={country.id} selected={country.id === 229}>
                                                                    {country.phone_code}
                                                                </option>
                                                            ))}
                                            </select>
                                            <input
                                                type="number"
                                                name="contactNumber"
                                                id="InputContact1"
                                                className="form-control"
                                                value={profileData.contactNumber}
                                                onChange={handleProfileChange}
                                                placeholder={t('PROFILE.ENTERCONTACTNUMBER')}
                                            />
                                        </div>
                                        {/* Optional validation message */}
                                        {/* {blnSubmitted && (!profileData.country_id || !profileData.contactNumber) && (
                                            <span className="validationMsg">
                                                {t('PROFILE.COUNTRYCODEANDPHNNUMBER')}
                                            </span>
                                        )} */}
                                    </div>


                                    <div className="row">



                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">{t('CONTACT.EMAIL')}</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={profileData.email}
                                            placeholder={t('PROFILE.EMAIL')}
                                            onChange={handleProfileChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">{t('PROFILE.ADDRESS')}</label>
                                        <textarea
                                            id="address"
                                            name="address"
                                            value={profileData.address}
                                            onChange={handleProfileChange}
                                        />
                                    </div>
                                    {/* <div className="form-group">
                    <button type="submit" className="btn btn-primary">
                        {t('PROFILE.SAVE')}
                    </button>
                </div> */}
                                </form>
                                <div className="nex-prev">
                                    <a className="cancel" onClick={handleProfileCancel}>
                                        {t('BOOKNOW.BACK')}
                                    </a>
                                    <a className="next" onClick={handleProfileNext}>
                                        {t('BOOKNOW.NEXT')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}

                    {/*    <!-------End Profile section---------->*/}

                    {/*  <!-----Uplaod document Section---->*/}

                    {blnUploadModal && (
                        <div className="upload-document" style={{ textAlign: 'start' }}>
                            <div className="selectType" >
                                <h5>{/* Replace with your translation method */}{t('VERIFICATION.RSIDENTORNOT')}</h5>
                                <div className="selectHere">
                                    <input
                                        type="radio"
                                        id="resident"
                                        name="fav_type"
                                        value="RESIDENT"
                                        onClick={(e) => getDocuments(e, '1')}
                                        checked={touristOrResident === 'RESIDENT'}
                                    />
                                    <label htmlFor="resident">{/* Replace with your translation method */}{t('VERIFICATION.RESIDENT')}</label>

                                    <input
                                        type="radio"
                                        id="tourist"
                                        name="fav_type"
                                        value="TOURISTS"
                                        onClick={(e) => getDocuments(e, '2')}
                                        checked={touristOrResident === 'TOURISTS'}
                                    />
                                    <label htmlFor="tourist">{/* Replace with your translation method */}{t('VERIFICATION.TOURIST')}</label>
                                </div>
                            </div>
                            <h5>{/* Replace with your translation method */}{t('BOOKNOW.UPLOADDOCUMENTS')}</h5>
                            <div className="boxDocuments">
                                <div className="drivers">
                                    {lstDocuments.map((doc, index) => (
                                        <div className="box" key={index}>
                                            <p>{doc.name}</p>
                                            {!doc.fileName && doc.is_approved === 2 && (
                                                <span style={{ fontSize: '10px' }}>
                                                    {/* Replace with your translation method */}{t('BOOKING.VERIFICATIONPENDING')}
                                                </span>
                                            )}
                                            {!doc.fileName && doc.is_approved === 1 && (
                                                <span style={{ fontSize: '10px' }}>
                                                    {/* Replace with your translation method */}{t('BOOKING.APPROVED')}
                                                </span>
                                            )}
                                            {!doc.fileName && doc.is_approved === 0 && (
                                                <span style={{ fontSize: '10px' }}>
                                                    {/* Replace with your translation method */}{t('BOOKING.REJECTED')}
                                                </span>
                                            )}
                                            {!doc.fileName && doc.is_approved === null && (
                                                <span style={{ fontSize: '10px' }}>
                                                    {/* Replace with your translation method */}{t('VERIFICATION.NOTUPLOADED')}
                                                </span>
                                            )}
                                            {doc.fileName && <span style={{ fontSize: '10px' }}>{doc.fileName}</span>}
                                            <input
                                                type="file"
                                                name="UploadyourCVResume"
                                                size="40"
                                                className="form-control"
                                                id="file"
                                                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                                                aria-required="true"
                                                aria-invalid="false"
                                                // onChange={(e) => onChange(e, doc)}
                                                style={{ display: doc.is_uploaded ? 'none' : 'block' }}
                                            />
                                            {doc.is_uploaded && (
                                                <input
                                                    type="file"
                                                    name="UploadyourCVResume"
                                                    size="40"
                                                    className="form-control changeDoc"
                                                    id="file"
                                                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                                                    aria-required="true"
                                                    aria-invalid="false"
                                                // onChange={(e) => onChange(e, doc)}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="nex-prev">
                                <a
                                    // onClick={backToCalendar} 
                                    className="cancel">
                                    {/* Replace with your translation method */}{t('BOOKNOW.BACK')}
                                </a>
                                <a
                                    // onClick={uploadDocumentNext} 
                                    className={`next ${blnUploadStart ? 'btn-loading' : ''}`}>
                                    {/* Replace with your translation method */}{t('BOOKNOW.NEXT')}
                                </a>
                            </div>
                        </div>
                    )}


                    {/*  <!-----End Uplaod document Section---->*/}


                </div>
                <ToastContainer></ToastContainer>
            </div>


        </>


    );
}

export default BookSection;